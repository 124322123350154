import { render, staticRenderFns } from "./DetailContent.vue?vue&type=template&id=035f2504&scoped=true&"
import script from "./DetailContent.vue?vue&type=script&lang=js&"
export * from "./DetailContent.vue?vue&type=script&lang=js&"
import style0 from "./DetailContent.vue?vue&type=style&index=0&id=035f2504&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035f2504",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardTitle,VDataTable,VListItemSubtitle,VPagination,VProgressCircular})
