<template>
  <v-container
    tag="div"
    class="container-width pa-0"
  >
    <BaseDetail :isScroll="false">
      <template #header>
        <nobr></nobr>
      </template>
      <template #content>
        <div>
          <BaseHeader
            :save-btn="false"
            :delete-btn="false"
            @close="handleClose"
            :confirm-btn="isActiveButtons"
            :reject-btn="isActiveButtons"
            @reject="onReject"
            @confirm="onConfirmCommit"
          />
          <DetailContent
            :value="value"
            :commit-id="commitId"
          />
        </div>
      </template>
    </BaseDetail>
    <BaseDialog
      is-confirm-btn
      is-cancel-btn
      label="Причина отклонения"
      :value="isDialogCancel"
      @confirm="rejectCommit"
      @input="onCloseDialog"
      :max-width="800"
      :text-confirm="$t('base.ok')"
      :text-cancel="$t('base.close')"
    >
      <template #content>
        <v-textarea
          placeholder="Напишите причину отклонения"
          @change="onChangeReasonReject"
        />
      </template>
    </BaseDialog>
    <BaseDialog
      label="Подтвердить данные паспорта?"
      @confirm="confirmInDialog"
      @input="isDialogVerify = false"
      is-cancel-btn
      is-confirm-btn
      :text-cancel="$t('base.close')"
      :max-width="350"
      :value="isDialogVerify"
    />
  </v-container>
</template>

<script>
import BaseDetail from '@/components/base/BaseDetail'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseDialog from '@/components/base/BaseDialog'
import DetailContent from '@/components/views/account/verification/detail/detailContent/DetailContent'
import validationErrors from '@/components/mixins/validationErrors'
import store from '@/store'
import backToTables from '@/components/mixins/backToTables'

export default {
  name: 'DetailView',
  mixins: [validationErrors, backToTables],
  components: { BaseHeader, BaseDetail, BaseDialog, DetailContent },
  props: {
    value: Object,
    checklist: Array,
    commitId: Number
  },
  data () {
    return {
      changedItem: {},
      isDialogCancel: false,
      detail: {},
      note: '',
      isDialogVerify: false
    }
  },
  computed: {
    isApplyToEdit () {
      return ['admin', 'auditor'].includes(store.getters['auth/getCurrentRole'])
    },
    isActiveButtons () {
      return this.value.status === 1 && this.isApplyToEdit
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onReject () {
      this.isDialogCancel = !this.isDialogCancel
    },
    async rejectCommit () {
      this.detail.note = this.note
      this.detail.status = 2
      this.detail = await this.$store.dispatch('verification/changeStatusVerification', {
        data: this.detail
      })
      this.isDialogCancel = !this.isDialogCancel
    },
    onChangeReasonReject (event) {
      this.note = event
    },
    onCloseDialog () {
      this.isDialogCancel = !this.isDialogCancel
      this.note = ''
    },
    async onConfirmCommit () {
      if (this.isApplyToEdit) {
        this.isDialogVerify = true
      } else {
        this.detail.status = 3
        this.detail = await this.$store.dispatch('verification/changeStatusVerification', {
          data: this.detail
        })
      }
    },
    async confirmInDialog () {
      this.detail.status = 3
      this.detail = await this.$store.dispatch('verification/changeStatusVerification', {
        data: this.detail
      })
      this.isDialogVerify = false
    }
  }
}
</script>

<style>
  .container-width {
    max-width: unset;
  }
</style>
