import _ from 'lodash'
import { cleanClone, deepEqual, sortWithNull } from '@/components/utils/common'
import { filterBaseAttrs } from '@/config/common'
//todo: рефактор всего
export default {
  props: {
    commitId: Number
  },
  data () {
    return {
      isLoading: false,
      diffVerifications: [],
      originVerifications: [],
      changesVerification: [], //итоговый массив с измененными объектами
      scenario: 'diff',
      paginationData: {
        filter: {},
        options: {}
      }
    }
  },
  methods: {
    async fillData () {
      if (!this.isLoading) {
        this.isLoading = true
        const response = await this.$store.dispatch('server/get', {
          url: '/object?',
          params: this.pagination
        })
        this.$set(this.diffVerifications, 'difference', response)
        this.createOrigin()
        this.createChangesVerification()
        this.isLoading = false
      }
      return true
    },
    createOrigin () {
      const origin = []
      this.diffVerifications.difference.models.map((item) => {
        if (item.properties.origin !== null) {
          origin.push(item.properties.origin)
        } else {
          origin.push({})
        }
      })
      this.$set(this.originVerifications, 'origin', origin)
    },
    createChangesVerification () {
      const changes = []
      const elementNumbers = []
      //создание объекта с отличающимися properties оригинала и нового объекта
      for (let i = 0; i < this.diffVerifications.difference.models.length;) {
        this.originVerifications.origin.map((originItem, index) => {
          const extraAttributes = ['origin', 'state', 'id', 'created_at', 'updated_at', 'origin_id', 'verification_status', 'passport_id', 'eav_entity_id']
          // extraAttributes - это аттрибуты, которые не должны входить в список изменений. Из-за некоторых аттр. могут быть баги(если их не включить в этот список). Например objectFiles.
          const diffCopy = this.diffVerifications.difference.models.slice()
          const keysProperties = _.union(_.keys(diffCopy[index].properties), _.keys(originItem.properties)) //массив ключей которые есть в обоих объектах
          const differenceProperties = _.omit(_.reduce(keysProperties, function (result, key) {
            const diffProp = diffCopy[index]?.properties[key]
            if (diffCopy[index]?.properties?.state === 2) {
              result.state = { origin: originItem?.properties.state, diff: 'Отправлен на удаление' }
            } else if (diffProp && Object.keys(originItem).length !== 0 && !_.isEqual(diffProp, originItem.properties[key])) {
              result[key] = { diff: diffProp || null, origin: originItem?.properties[key] }
            } else if (!!diffProp && Object.keys(originItem).length === 0) {//новый объект
              result[key] = { diff: diffProp || null, origin: null }
            }
            return result
          }, {}), extraAttributes)

          if (diffCopy[index]?.properties?.state !== 2) {
            if (Object.keys(originItem).length === 0 && !deepEqual(originItem?.geometry, diffCopy[index]?.geometry)) {
              differenceProperties.geometry = {
                origin: '—',
                diff: 'Геометрия изменена'
              }
            } else if (!deepEqual(originItem?.geometry, diffCopy[index]?.geometry)) {
              differenceProperties.geometry = {
                origin: 'Геометрия изменена',
                diff: 'Геометрия изменена'
              }
            }

            if (Object.keys(originItem).length === 0 && diffCopy[index]?.properties.objectFiles) {
              differenceProperties.objectFiles = {
                origin: `—`,
                diff: `Добавленно: ` + diffCopy[index].properties.objectFiles.length
              }
            } else if (!deepEqual(originItem?.properties.objectFiles, diffCopy[index]?.properties.objectFiles)) {
              differenceProperties.objectFiles = this.arrayDiffCount(originItem.properties.objectFiles, diffCopy[index].properties.objectFiles, 'file')
            }

            if (Object.keys(originItem).length === 0 && diffCopy[index]?.properties.objectImages) {
              differenceProperties.objectImages = {
                origin: `—`,
                diff: `Добавленно: ` + diffCopy[index].properties.objectImages.length
              }
            } else if (!deepEqual(originItem?.properties.objectImages, diffCopy[index]?.properties.objectImages)) {
              differenceProperties.objectImages = this.arrayDiffCount(originItem.properties.objectImages, diffCopy[index].properties.objectImages, 'image')
            }
          }
          if (Object.values(differenceProperties).length) {
            changes.push({ properties: differenceProperties })
            elementNumbers.push(this.diffVerifications.difference.models[index].properties?.element_number || '—')
          }
          i++
        })
      }
      this.$set(this.changesVerification, 'changes', changes)
      this.$set(this.changesVerification, 'count', this.diffVerifications.difference.count)
      this.$set(this.changesVerification, 'numbers', elementNumbers)
    },
    arrayDiffCount (origin, diffCopy, objName) {
      //todo: Кирилл. Разбить на методы для подсчета разница и формирования строки. Сделать его универсальным.
      let identicallyCount = _.intersectionWith(origin, diffCopy, (itemOrigin, itemDiffCopy) => itemOrigin[objName].file_name === itemDiffCopy[objName].file_name).length
      let deletedCount = origin.length - identicallyCount
      let addedCount = diffCopy.length - identicallyCount
      let stringForDiff = ''
      if (addedCount) {
        stringForDiff = `Добавленно: ` + addedCount + `; `
      } else if (deletedCount) {
        stringForDiff = stringForDiff + `Удалено: ` + deletedCount + `; `
      } else if (origin.length === diffCopy.length) { //если количество фото/файлы не изменилось, но сами фото поменялись
        for (let i = 0; i < origin.length; i++) {
          if (!_.isEqual(_.pick(origin[i], 'id'), _.pick(diffCopy[i], 'id'))) { //сравнение по id
            stringForDiff = 'Заменены'
          }
        }
      }
      return {
        origin: 'Кол-во: ' + origin.length,
        diff: stringForDiff
      }
    },
    findByName (name) {
      return this.labels.find((item) => item.value === name)
    },
    getLabel (name) {
      return this.findByName(name)?.text || ''
    },
    getValue (name, value) {
      const optionCategoryId = this.findByName(name).optionCategoryId
      if (Array.isArray(value) && optionCategoryId) {
        let valueArray = ''
        let formattedValue = value.slice().forEach(item => valueArray += this.$store.getters['handbook/getCategoryValue'](optionCategoryId, item) + ' ')
        formattedValue = valueArray
        return formattedValue ? formattedValue : '—'
      } else {
        return !!optionCategoryId && !!value ? this.$store.getters['handbook/getCategoryValue'](optionCategoryId, value) : !!value ? String(value) : '—'
      }
    }
  },
  computed: {
    pagination () {
      // это для запроса.
      this.paginationData.options = {
        page: 1,
        itemsPerPage: 10
      }
      this.paginationData.filter = {
        eav_entity_id: this.commitId ? this.commitId : this.$route.params.commitId,
        passport_id: this.value.passport.properties.id,
        verificationScenario: this.scenario
      }
      return this.paginationData
    },
    entityId () {
      if (this.commitId) {
        return this.commitId
      } else {
        return this.$route.params.commitId * 1
      }
    },
    objectAttributes () {
      const filteredBase = this.eavEntity.eavEntity.baseAttributes.filter(item => filterBaseAttrs.includes(item.name))
      return cleanClone(filteredBase.concat(this.eavEntity.eavEntity.eavAttributes))
    },
    eavEntity () {
      return cleanClone(this.value.passport.properties.passportEntities.find(item => item.eavEntity.id === this.entityId))
    },
    objectAttributesFlat () {
      // эта функция такая же как и в PassportObjectsTable.vue, может ее вынести куда-то и просто переиспользовать
      //objectAttributes тоже потворяется,но лежит в миксине для паспорта
      let relatedAttributes = []
      if (this.eavEntity) {
        this.eavEntity.eavEntity.eavAttributes.forEach(item => {
          if (item.relatedAttributes && item.relatedAttributes.length) {
            relatedAttributes = relatedAttributes.concat(item.relatedAttributes)
          }
        })
        relatedAttributes = this.objectAttributes.concat(relatedAttributes)
      }
      return relatedAttributes
    },
    labels () {
      if (this.value && Object.keys(this.value).length) {
        let result = this.objectAttributesFlat.sort(sortWithNull('order')).map(item => ({
            text: item.label,
            value: item.name,
            optionCategoryId: item?.optionCategoryId,
            type: item.eavType.name
          })
        )
        result.push({ // добавляем Файлы и Фото
          text: 'Файлы',
          value: 'objectFiles',
          optionCategoryId: null,
          type: 'array'
        }, {
          text: 'Фото',
          value: 'objectImages',
          optionCategoryId: null,
          type: 'array'
        })
        return result
      }
    }
  },
  async mounted () {
    return this.fillData()
  }
}
