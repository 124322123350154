<template>
  <!--todo: тотальный рефактор. вынести блок рендера объекта в отдельный компонент, тут оставить пагинацию-->
  <div class="content-container">
    <div
      v-if="changesVerification.changes.length > 0"
      v-for="(itemChanges, indexChanges) in changesVerification.changes"
      :key="indexChanges"
    >
      <v-card
        class="mt-6"
        v-if="createArrayForTable(itemChanges.properties).length || getStatusChanges(indexChanges) !== 'Измененный'"
      >
        <v-card-title>
          Номер элемента: {{ changesVerification.numbers[indexChanges] }}
          <v-list-item-subtitle>Статус: {{ getStatusChanges(indexChanges) }}</v-list-item-subtitle>
        </v-card-title>
        <v-data-table
          v-if="itemChanges.properties && getStatusChanges(indexChanges) !== 'Удаленный'"
          dense
          hide-default-footer
          :loading="isLoading"
          :items-per-page="Object.keys(itemChanges.properties).length"
          :headers="[{text: 'Имя атрибута', value: 'name_attribute'}, {text: 'Старое значение', value: 'old'}, {text: 'Изменения', value: 'new'}]"
          :items="createArrayForTable(itemChanges.properties)"
          :footer-props="{
            'items-per-page-options': [25, 50, 100]
          }"
        >
          <template v-slot:item="{item, index}">
            <tr
              v-for="(i, key) in item"
              :key="key"
            >
              <td>{{ getLabel(key) }}</td>
              <td>{{ getValue(key, i.origin) }}</td>
              <td>{{ getValue(key, i.diff) }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-card
      v-if="!isChanges && !isLoading"
      outlined
      class="pa-2 ml-2"
    >
      Информация отсутствует
    </v-card>
    <v-progress-circular
      indeterminate
      color="primary"
      style="width: 100%"
      v-if="isLoading"
    ></v-progress-circular>
    <v-pagination
      v-if="changesVerification.count > 10"
      :total-visible="10"
      class="mt-2"
      :length="countPages"
      v-model:value="paginationData.options.page"
    />
  </div>
</template>

<script>
import BaseDetail from '@/components/base/BaseDetail'
import BaseSideBar from '@/components/base/BaseSideBar'
import verification from '@/components/views/account/verification/verification'
import verificationDetail from '@/components/views/account/verification/verificationDetail'

export default {
  name: 'DetailContent',
  mixins: [verification, verificationDetail],
  components: { BaseDetail, BaseSideBar },
  data () {
    return {
      currentCountVisible: 10
    }
  },
  watch: {
    'pagination.options.page': {
      handler () {
        this.fillData()
      }
    }
  },
  methods: {
    createArrayForTable (obj) {
      // преобразование большого объекта properties в массив с объектами
      const arrayForTable = []
      for (let item in obj) {
        if (obj[item].diff !== '') {
          arrayForTable.push({ [item]: obj[item] })
        }
      }
      return arrayForTable
    },
    getStatusChanges (index) {
      if (this.diffVerifications.difference.models[index].properties.state === 2 && this.diffVerifications.difference.models[index].properties.origin) {
        return 'Удаленный'
      } else if (this.diffVerifications.difference.models[index].properties.verification_status === 3) {
        if (!this.diffVerifications.difference.models[index].properties.origin) {
          return 'Новый'
        } else if (this.diffVerifications.difference.models[index].properties.origin.properties.state === 2 && this.diffVerifications.difference.models[index].properties.state === 1) {
          return 'Восстановленный'
        } else {
          return 'Измененный'
        }
      }
    }
  },
  computed: {
    countPages: function () {
      return Math.ceil(this.changesVerification.count / this.currentCountVisible)
    },
    isChanges () {
      return this.changesVerification?.changes?.length
    }
  }
}
</script>

<style scoped>
  .content-container {
    height: calc(100vh - 75px);
    overflow-y: auto;
  }
  /deep/ .v-banner__wrapper {
    border-bottom: none !important;
  }
  .group-expanded > /deep/ .v-list-item {
    padding: 0 !important;
  }
</style>
